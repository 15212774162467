import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import Message from 'components/content/Message';
export const _frontmatter = {
  "path": "/developer/asp-net/deploying-aspnet-mvc-applications",
  "date": "2013-05-07",
  "title": "DEPLOYING ASP.NET MVC APPLICATIONS",
  "author": "admin",
  "tags": ["development", "javascript"],
  "featuredImage": "feature.jpg",
  "excerpt": "In a continuous integration environment, deploying ASP.NET MVC applications are done by a click of a button, triggered by a check-in, and then  many moving parts of the integration service will take care of distributing the artifacts to the relevant  servers. But here we are discussing about how to get your ASP.NET code to be tested on a local IIS server environment. So let’s start with dependancies, and what needs to be included with the deployment."
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`High level ASP.NET MVC website dependencies are:`}</p>
    <ul>
      <li parentName="ul">{`The .NET Framework`}</li>
      <li parentName="ul">{`Static content - eg: javascript, css, images and content folders`}</li>
      <li parentName="ul">{`External services - eg: database, web services etc`}</li>
    </ul>
    <p>{`What to include with deployment:`}</p>
    <ul>
      <li parentName="ul">{`/bin/folder - compiled code`}</li>
      <li parentName="ul">{`Special files - eg: Web.Config, Global.asax`}</li>
      <li parentName="ul">{`Static content - eg: javascript, css, images and content folders`}</li>
      <li parentName="ul">{`ASP.NET MVC diverges when it comes to views - include everything under Views folder`}</li>
    </ul>
    <p>{`What not to include:`}</p>
    <ul>
      <li parentName="ul">{`Source code files (already included as compiled binaries)	`}</li>
    </ul>
    <h2>{`Deployment checklist`}</h2>
    <ul>
      <li parentName="ul">{`What system-level applications and APIs does the application require (eg: OS version, IIS version, .NET Framework version) - does any software need to be installed on the server?`}</li>
      <li parentName="ul">{`What system level folders or files does teh application require?`}</li>
      <li parentName="ul">{`Does the application require databases?  - is it properly configured (ie. users created etc)? - have there been any updates to the database scheme since the last release?`}</li>
      <li parentName="ul">{`What other servers or services does the application interact with? (firewall rules etc)`}</li>
      <li parentName="ul">{`Do I have all of the appropriate licenses purchase and available? `}</li>
    </ul>
    <h2>{`Deploying to IIS`}</h2>
    <ul>
      <li parentName="ul">{`Hosting ASP.NET MVC is similar to ASP.NET`}</li>
      <li parentName="ul">{`Ensure that the target web server has .NET 4.0 or later`}</li>
    </ul>
    <p><img parentName="p" {...{
        "src": "https://i.imgur.com/U6sJ3J3.png",
        "alt": "ASPNET add_application"
      }}></img></p>
    <p>{`Run the following script against the database to add a login as a member of a fixed server role. (or use  `}{`[http://msdn.microsoft.com/en-us/library/ee677634.aspx]`}{`(ALTER SERVER ROLE) instead as this will be deprecated soon)`}</p>
    <p><img parentName="p" {...{
        "src": "https://i.imgur.com/ZmCMecj.png",
        "alt": "ASPNET default_apppool_sql"
      }}></img></p>
    <p>{`If getting an access denies error, try adding the server name for database.`}</p>
    <p><img parentName="p" {...{
        "src": "https://i.imgur.com/zhRQP65.png",
        "alt": "ASPNET add_connection"
      }}></img></p>
    <p>{`Right click on the project and select publish to publish the web site.`}</p>
    <p><img parentName="p" {...{
        "src": "https://i.imgur.com/ecBOFPy.png",
        "alt": "ASPNET publish_web"
      }}></img></p>
    <p>{`Run the following command on Visual Studio Command Prompt.`}</p>
    <p><img parentName="p" {...{
        "src": "https://i.imgur.com/xRqssmR.jpg",
        "alt": "7a1a1e73-b9af-476d-9818-e9c96d32f220"
      }}></img></p>
    <p>{`Change the connection string to meet these shared settings.`}</p>
    <p>{`And voilà,  it's out there to be tested!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      